.footer-item {
  /* You can add common styles for all footer items here if needed */
}

.footer-item.left {
  text-align: left;
}

.footer-item.center {
  text-align: center;
}

.footer-item.right {
  text-align: right;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .footer-item.left,
  .footer-item.center,
  .footer-item.right {
    text-align: center; /* Center-align all items on smaller screens */
    margin-bottom: 10px; /* Add some space between stacked items */
  }
}