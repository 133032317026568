.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5;
  padding: 10px 20px;
}

.brand-name {
  font-size: 24px;
  font-weight: bold;
}

/* Existing styles */
a:hover {
background-color: #23272b;
transform: scale(1.05);
}

a:active {
background-color: #1d2124;
transform: scale(0.95);
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    text-align: center;
  }

  .brand-name {
    margin-bottom: 10px;
  }

  .header nav a {
    display: block;
    margin: 5px 0; /* Stack links with space */
    width: 100%; /* Full width for easier touch */
  }
}