.carousel {
  position: relative;
  width: 100%;
  height: 60vh; /* Adjust as needed */
  overflow: hidden;
}

.carousel-item {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  display: flex;
  transition: opacity 0.5s ease;
  opacity: 0;
}

.carousel-item.active {
  opacity: 1;
}

.carousel-text {
  flex: 1;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  flex-direction: column;
}

.carousel-image {
  flex: 2;
  background-size: cover;
  background-position: center;
}

.carousel-text h2 {
  font-size: 24px; /* Large font size for header */
  margin: 0;
  color: #fff; /* Adjust color as needed */
}

.carousel-text p {
  font-size: 16px; /* Readable font size for body */
  margin: 0;
  color: #ddd; /* Adjust color as needed */
}

@media (max-width: 768px) {
  .carousel {
    height: 40vh; /* Smaller height for mobile devices */
  }

  .carousel-text {
    padding: 10px; /* Less padding on smaller screens */
    align-items: center; /* Center text for better readability */
    text-align: center;
  }

  .carousel-text h2 {
    font-size: 20px; /* Smaller font size for header */
  }

  .carousel-text p {
    font-size: 14px; /* Smaller font size for body */
  }
}