.main-section {
  padding: 40px;
  text-align: center;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .main-section {
    padding: 20px; /* Reduced padding for smaller screens */
  }

  .main-section h2 {
    font-size: 1.5em; /* Adjust font size if necessary */
  }

  .main-section p {
    font-size: 1em; /* Adjust paragraph font size for readability */
  }
}