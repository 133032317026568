.footer {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #333;
  color: white;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .footer {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center-align items */
    text-align: center; /* Center-align text */
  }
}